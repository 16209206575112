import { Transition } from "@headlessui/react";
import type { LegacyRef } from "react";
import { useEffect, useRef, useState } from "react";
import type { Country } from "@components/layout/Navbar/Navbar.types.ts";
import { cn } from "@core/utils";
import i18next from "i18next";

function getLabel(
	country: Country,
	currentLanguage: keyof typeof country.label
): string {
	return country.label[currentLanguage];
}

const LanguageToggle = () => {
	const countries: Country[] = [
		{ flag: "us", label: { en: "English", fr: "Anglais" }, lang: "en" },
		{ flag: "fr", label: { en: "French", fr: "Français" }, lang: "fr" },
	];
	const menuRef = useRef<HTMLDivElement>();
	const toggleButtonRef = useRef<HTMLButtonElement>();
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const currentLanguage = i18next.resolvedLanguage || i18next.language;

	const selectCountry = (index: number) => {
		setIsMenuOpen(false);
		void i18next.changeLanguage(countries[index].lang);
	};

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				menuRef.current &&
				!menuRef.current?.contains(event.target as Node) &&
				!toggleButtonRef.current?.contains(event.target as Node)
			) {
				setIsMenuOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<button
			role={"button"}
			className="z-0 flex cursor-pointer items-center justify-center rounded-md bg-logoWhite px-3 py-2 hover:bg-slate-100"
			onClick={toggleMenu}
			ref={toggleButtonRef as LegacyRef<HTMLButtonElement>}
		>
			<div className="w-full">
				<div className="align-items-center flex w-full justify-center">
					<div className="relative">
						<span
							className={cn("font-semibold uppercase", "notranslate")}
							lang={currentLanguage}
							translate={"no"}
						>
							{currentLanguage}
						</span>
						<Transition
							show={isMenuOpen}
							enter="transition-opacity duration-75"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity duration-75"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							{() => (
								<div className="shadow-md text-gray-700 absolute right-0 top-0 z-30 mt-12 w-48 min-w-full rounded bg-white text-sm">
									<span
										aria-hidden
										className="absolute right-0 top-0 -mt-1 mr-3 h-3 w-3 rotate-45 transform bg-white"
									></span>
									<div
										className="relative z-10 w-full overflow-auto rounded bg-white p-2"
										ref={menuRef as LegacyRef<HTMLDivElement>}
									>
										<ul className="list-reset space-y-[3px]">
											{countries.map((item, index) => (
												<li className="flex" key={item.lang}>
													<div
														role="button"
														aria-label={"Language: " + item.lang}
														tabIndex={0}
														className={cn(
															"flex w-full rounded-md px-4 py-2 no-underline transition-colors duration-100 hover:bg-primary-50/50 hover:no-underline",
															i18next.resolvedLanguage === item.lang
																? "bg-primary-50 text-text-400"
																: ""
														)}
														onClick={() => {
															selectCountry(index);
														}}
													>
														{getLabel(
															item,
															i18next.resolvedLanguage as keyof typeof item.label
														)}
													</div>
												</li>
											))}
										</ul>
									</div>
								</div>
							)}
						</Transition>
					</div>
				</div>
			</div>{" "}
		</button>
	);
};

export default LanguageToggle;

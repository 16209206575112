import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import "@i18n/i18n";
import store from "@store/index";
import { ThemeContextProvider } from "@contexts/theme.provider";
import type { ReactNode } from "react";

type AppProviderWrapper = {
	children: ReactNode;
	className?: string;
};

export default function AppProviderWrapper({ children }: AppProviderWrapper) {
	const queryClient = new QueryClient(/* optional config */);
	return (
		<Provider store={store}>
			<ThemeContextProvider>
				<QueryClientProvider client={queryClient}>
					<>{children}</>
				</QueryClientProvider>
			</ThemeContextProvider>
		</Provider>
	);
}

import { createRouter } from "@tanstack/react-router";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { routeTree } from "./routeTree.gen.ts";
import "./styles/globals.scss";
import "swiper/css/free-mode";
import "swiper/css";
import "swiper/css/pagination";
import "keen-slider/keen-slider.min.css";
import NotFound from "@pages/(app)/NotFound.tsx";

const router = createRouter({
	routeTree,
	defaultPreload: "intent",
	defaultNotFoundComponent: NotFound,
});

declare module "@tanstack/react-router" {
	interface Register {
		// This infers the type of our router and registers it across your entire project
		router: typeof router;
	}
}

const rootElement = document.querySelector("#root") as Element;
if (!rootElement.innerHTML) {
	const root = ReactDOM.createRoot(rootElement);
	// rootElement.classList.add("bg-BGPage", "font-poppins", "min-h-screen");
	root.render(
		<React.StrictMode>
			<App router={router} />
		</React.StrictMode>,
	);
}

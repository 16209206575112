/*================================ Navbar Items ==============================*/

export const navBarItems = [
	{
		title: "navbar.home",
		icon: undefined,
		link: "/",
	},
	{
		title: "navbar.services.title",
		icon: undefined,
		link: "/services",
		child: [
			{
				childtitle: "navbar.services.enterprise",
				childlink: "/solutions/enterprise",
			},
			{
				childtitle: "navbar.services.startup",
				childlink: "/solutions/startup",
			},
			{
				childtitle: "navbar.services.employability",
				childlink: "/solutions/employability",
			},
			{
				childtitle: "navbar.services.researcher",
				childlink: "/solutions/researcher",
			},
		],
	},
	{
		title: "navbar.coachiniStudio.title",
		icon: undefined,
		link: "/studio",
	},
	// {
	//   title: "navbar.coachiniStudio.title",
	//   icon: undefined,
	//   link: "/coachini-studio",
	//   child: [
	//     {
	//       childtitle: "navbar.coachiniStudio.cv",
	//       childlink: "/studio/cv",
	//     },
	//     {
	//       childtitle: "navbar.coachiniStudio.pitch",
	//       childlink: "/studio/pitch",
	//     },
	//     {
	//       childtitle: "navbar.coachiniStudio.podcast",
	//       childlink: "/studio/podcast",
	//     },
	//   ],
	// },
	{
		title: "navbar.about",
		icon: undefined,
		link: "/about",
	},
	{
		title: "navbar.coaches",
		icon: undefined,
		link: "/coaches",
	},
];
/*================================ Menu Items ==============================*/
export const menuItems = [
	{
		isHeadr: true,
		title: "menu",
	},

	{
		title: "Dashboard",
		icon: "heroicons-outline:home",
		// isOpen: true,
		isHide: true,
		link: "/dashboard",
	},
	{
		isHeadr: true,
		title: "Booking",
	},
	{
		title: "Create Reservation",
		isHide: true,
		icon: "heroicons-outline:squares-plus",
		link: "/dashboard/create-booking",
	},
	{
		title: "All Bookings",
		isHide: true,
		icon: "heroicons-outline:device-tablet",
		link: "/dashboard/bookings",
	},
	{
		title: "Calender",
		isHide: true,
		icon: "heroicons-outline:calendar",
		link: "/dashboard/calendar",
	},

	{
		title: "Planning",
		isHide: true,
		icon: "heroicons-outline:clipboard-check",
		link: "/dashboard/todo",
	},
	{
		isHeadr: true,
		title: "Payment",
	},
	{
		title: "Wallet",
		isHide: true,
		icon: "heroicons-outline:currency-dollar",
		link: "wallet",
	},
	{
		title: "Guide",
		isHide: true,
		icon: "heroicons-outline:question-mark-circle",
		link: "wallet",
	},
];

import type { LucideIcon } from "lucide-react";
import * as React from "react";
import type { IconType } from "react-icons";
import { ImSpinner2 } from "react-icons/im";
import { Link } from "@tanstack/react-router";
import { cn } from "@core/utils";

const ButtonVariant = [
	"primary",
	"secondary",
	"outline",
	"ghost",
	"light",
	"dark",
] as const;
const ButtonSize = ["sm", "md", "lg", "base"] as const;

type ButtonProps = {
	link?: string;
	isLoading?: boolean;
	isDarkBg?: boolean;
	variant?: (typeof ButtonVariant)[number];
	size?: (typeof ButtonSize)[number];
	leftIcon?: IconType | LucideIcon;
	rightIcon?: IconType | LucideIcon;
	classNames?: {
		leftIcon?: string;
		rightIcon?: string;
	};
} & React.ComponentPropsWithRef<"button">;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			children,
			link,
			className,
			disabled: buttonDisabled,
			isLoading,
			variant = "primary",
			size = "base",
			isDarkBg = false,
			leftIcon: LeftIcon,
			rightIcon: RightIcon,
			classNames,
			...rest
		},
		ref
	) => {
		const disabled = isLoading || buttonDisabled;

		return (
			<button
				ref={ref}
				type="button"
				disabled={disabled}
				className={cn(
					"relative inline-flex items-center rounded-[10px] font-medium",
					"focus:outline-none focus-visible:ring focus-visible:ring-primary-500",
					"shadow-sm shadow-xSm",
					"transition-colors duration-200 ease-in-out active:duration-100",
					"justify-center",
					//#region  //*=========== Size ===========
					[
						size === "sm" && ["px-2 py-1", "text-buttonSmall"],
						size === "md" && ["px-3 py-1.5", "text-buttonMedium"],
						size === "lg" && ["px-4 py-2", "text-buttonLarge"],
						size === "base" && ["px-5 py-2", "buttonText"],
					],
					//#endregion  //*======== Size ===========
					//#region  //*=========== Variants ===========
					[
						variant === "primary" && [
							"bg-primary-900 text-white",
							"hover:bg-primary-600 hover:text-white",
							"active:bg-primary-700",
							"disabled:bg-primary-500",
						],
						variant === "secondary" && [
							"bg-primary-50 text-primary-900",
							"hover:bg-primary-200 hover:text-primary-900",
							"active:bg-primary-100",
							"disabled:bg-primary-500",
						],
						variant === "outline" && [
							"text-primary-500",
							"border border-primary-500",
							"hover:bg-primary-50 active:bg-primary-100 disabled:bg-primary-100",
							isDarkBg &&
								"hover:bg-gray-900 active:bg-gray-800 disabled:bg-gray-800",
						],
						variant === "ghost" && [
							"text-primary-500",
							"shadow-none",
							"hover:bg-primary-50 active:bg-primary-100 disabled:bg-primary-100",
							isDarkBg &&
								"hover:bg-gray-900 active:bg-gray-800 disabled:bg-gray-800",
						],
						variant === "light" && [
							"text-gray-700 bg-white",
							"border-gray-300 border",
							"hover:text-dark hover:bg-gray-100",
							"disabled:bg-gray-200 active:bg-white/80",
						],
						variant === "dark" && [
							"bg-gray-900 text-white",
							"border-gray-600 border",
							"hover:bg-gray-800 active:bg-gray-700 disabled:bg-gray-700",
						],
					],
					//#endregion  //*======== Variants ===========
					"disabled:cursor-not-allowed",
					isLoading &&
						"text-transparent hover:text-transparent relative transition-none disabled:cursor-wait",
					className
				)}
				{...rest}
			>
				{isLoading && (
					<div
						className={cn(
							"absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2",
							{
								"text-white": ["primary", "dark"].includes(variant),
								"text-black": ["light"].includes(variant),
								"text-primary-500": ["outline", "ghost"].includes(variant),
							}
						)}
					>
						<ImSpinner2 className="animate-spin" />
					</div>
				)}
				{LeftIcon && (
					<div
						className={cn([
							size === "base" && "mr-1",
							size === "sm" && "mr-1.5",
						])}
					>
						<LeftIcon
							size="1em"
							className={cn(
								[
									size === "base" && "md:text-md text-md",
									size === "sm" && "md:text-md text-sm",
								],
								classNames?.leftIcon
							)}
						/>
					</div>
				)}
				{link && <Link to={link ?? ""} className="absolute inset-0" />}
				{children}
				{RightIcon && (
					<div
						className={cn([
							size === "base" && "ml-1",
							size === "sm" && "ml-1.5",
						])}
					>
						<RightIcon
							size="1em"
							className={cn(
								[
									size === "base" && "text-md md:text-md",
									size === "sm" && "md:text-md text-sm",
								],
								classNames?.rightIcon
							)}
						/>
					</div>
				)}
			</button>
		);
	}
);

export default Button;
